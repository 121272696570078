import { ActionTypes } from "@mui/base";
import { GET_SETTING, UPDATE_SETTING, UPDATE_SWITCH } from "./setting.type";

const initialState = {
  setting: {},
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTING:
      return {
        ...state,
        setting: action.payload,
      };

    case UPDATE_SETTING:
      return {
        ...state,
        setting: action.payload.data,
      };
    case UPDATE_SWITCH:
      return {
        ...state,
        setting: {
          ...state.setting,
          isActive: action.payload.isActive,
        },
      };

    default:
      return state;
  }
};

export default settingReducer;
