// GET_USER
export const GET_USER = "GET_USER";

//  get user profile
export const GET_USER_PROFILE = "GET_USER_PROFILE";

// user block
export const GET_USER_BLOCK = "GET_USER_BLOCK";

// get user history
export const GET_USER_HISTORY = "GET_USER_HISTORY";

// UPDATE_HOST_COIN
export const UPDATE_USER_COIN = "UPDATE_USER_COIN";

// delet post
export const DELETE_POST = "DELETE_POST"

export const GET_COUNTRY="GET_COUNTRY"

export const GET_PROFILE="GET_PROFILE"

export const GET_USER_ALL="GET_USER_ALL"


export const EDIT_USER="EDIT_USER"

export const UPDATE_STATUS="UPDATE_STATUS"  





