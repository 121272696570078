
import * as ActionType from "./types";

const initialState = {
  category: []

};

export const categoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_CATEGORY:
      return {
        ...state,
        category:action.payload.data
        
      }; 
      
      
    default:
      return state;
  }
};
