
import * as ActionType from "./types";

const initialState = {

  location:[]
  

 
};

export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
       
      case ActionType.GET_LOCATION:
        return{
          ...state,
          location: action.payload.data,
        }
  
      
        
        
      default:
        return state;
    }
  };