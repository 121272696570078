

import * as ActionType from "./types";

const initialState = {
  user: [],
  userProfile: {},
  profileUser:{},
  total: 0,
  dialog: false,
  dialogData: null,
  userAll:[],
  editUser:{},
  updateStatus:{},

  countryData:[]
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_USER:
      return {
        ...state,
        user: action.payload.user,
        total: action.payload.total,
      }; 
      case ActionType.GET_USER_ALL:
      return {
        ...state,
        userAll: action.payload.data,
        
      };     
    case ActionType.GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.data,
      };

    case ActionType.GET_PROFILE:
      return{
        ...state,
        profileUser: action.payload.data,
      }

    case ActionType.GET_USER_BLOCK:
      return {
        ...state,
        user: state.user.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
       
        userProfile: action.payload.data,
      };

    case ActionType.DELETE_POST:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          posts: state.userProfile.posts.filter((post) => post._id !== action.payload)
        },
      }
  
    
     
          case ActionType.GET_COUNTRY:
            return {
              ...state,
              countryData: action.payload,
            };
            
            case ActionType.EDIT_USER:
              return {
                ...state,
                editUser: action.payload,
              }; 


              case ActionType.UPDATE_STATUS:
              return {
                ...state,
                updateStatus: action.payload,
              };


              
         
      
      
    default:
      return state;
  }
};
