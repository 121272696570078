
import * as ActionType from "./types";

const initialState = {

  profileUser:{}
  

 
};

export const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
       
      case ActionType.GET_PROFILE:
        return{
          ...state,
          profileUser: action.payload.data,
        }
  
      
        
        
      default:
        return state;
    }
  };
  