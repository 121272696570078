// import {
//     CLOSE_NOTIFICATION_DIALOG,
//     CREATE_NEW_NOTIFICATION,
//     DELETE_NOTIFICATION,
//     EDIT_NOTIFICATION,
//     GET_NOTIFICATION,
//     OPEN_NOTIFICATION_DIALOG,
// } from "./types";

// const initialState = {
//   notification: [],
//   dialog: false,
//   dialogData: null,
// };

// const notificationReducer = (state = initialState, action) => {
//   switch (action.type) {
//     // case GET_NOTIFICATION:
//     //   return {
//     //     ...state,
//     //     notification: action.payload,
//     //   };
//     // case CREATE_NEW_NOTIFICATION:
//     //   const data = [...state.notification];
//     //   data.unshift(action.payload);
//     //   return {
//     //     ...state,
//     //     notification: data,
//     //   };
//     // case EDIT_NOTIFICATION:
//     //   return {
//     //     ...state,
//     //     notification: state.notification.map((notification) => {
//     //       if (notification._id === action.payload.id) return action.payload.data;
//     //       else return notification;
//     //     }),
//     //   };
//     // case DELETE_NOTIFICATION:
//     //   return {
//     //     ...state,
//     //     notification: state.notification.filter((notification) => notification._id !== action.payload),
//     //   };
//     // case VIP_SWITCH:
//     //   return {
//     //     ...state,
//     //     notification: state.notification.map((notification) => {
//     //       if (notification._id === action.payload._id)
//     //         return {
//     //           ...notification,
//     //           isVIP: action.payload.isVIP,
//     //         };
//     //       else return notification;
//     //     }),
//     //   };
//     case OPEN_NOTIFICATION_DIALOG:
//       return {
//         ...state,
//         dialog: true,
//         dialogData: action.payload || null,
//       };
//     case CLOSE_NOTIFICATION_DIALOG:
//       return {
//         ...state,
//         dialog: false,
//         dialogData: null,
//       };

//     default:
//       return state;
//   }
// };

// export default notificationReducer;



import * as ActionType from "./types";

const initialState = {

  info:{},
  infoCountry:{},
  infoUser:{}
  

 
};

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
       
      case ActionType.SEND_NOTIFICATION:
        return{
          ...state,
          info: action.payload.data,
        }
        case ActionType.SEND_NOTIFICATION_COUNTRY:
        return{
          ...state,
          infoCountry: action.payload.data,
        }
        case ActionType.SEND_NOTIFICATION_USER:
        return{
          ...state,
          infoUser: action.payload.data,
        }
  
      
        
        
      default:
        return state;
    }
  };
  