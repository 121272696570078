import React, { lazy, Suspense, useEffect } from "react";

import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { IdleTimeoutManager } from "idle-timer-manager";

import "./assets/css/main.css";
import "./assets/css/custom.css";
import "antd/dist/antd.less";
import "antd/dist/antd.min.css"

import $ from "jquery";
import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";

import setToken from "./util/SetToken";

const Login = lazy(() => import("./pages/LoginPage"));
const Admin = lazy(() => import("./pages/Admin"));
const AuthRouter = lazy(() => import("./util/AuthRouter"));
const PrivateRouter = lazy(() => import("./util/PrivateRoute"));

function App() {
  const isAuth = useSelector((state) => state.admin.isAuth);

  const dispatch = useDispatch();

  const token = sessionStorage.getItem("TOKEN");

  const key = sessionStorage.getItem("KEY");

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_ADMIN, payload: token });
  }, [setToken, key]);
  // useEffect(() => {
  //   const manager = new IdleTimeoutManager({
  //     timeout: 1800, //30 min (in sec)
  //     onExpired: (time) => {
  //       dispatch({ type: UNSET_ADMIN });
  //       return (window.location.href = "/");
  //     },
  //   });

  //   return () => {
  //     manager.clear();
  //   };
  // }, []);

  return (
    <Suspense fallback={""}>
      <Switch>
        {isAuth && <Route path="/admin" component={Admin} />}
        <AuthRouter exact path="/" component={Login} />
        <AuthRouter exact path="/login" component={Login} />
        <PrivateRouter path="/admin" component={Admin} />
      </Switch>
    </Suspense>
  );
}

export default App;

{
  /*<AuthRouter exact path="/unlock" component={UnlockScreenPage} />*/
}
{
  /*<Route exact path="/forgot" component={ForgotPassword} /> */
}
