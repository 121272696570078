
import * as ActionType from "./types";

const initialState = {

  resAnalityc:{}
  

 
};

export const analitycReducer = (state = initialState, action) => {
    switch (action.type) {
       
      case ActionType.GET_DATAWEEK:
        return{
          ...state,
          resAnalityc: action.payload.data,
        }
  
      
        
        
      default:
        return state;
    }
  };
  