import * as ActionType from "./dashboard.type";

const initialState = {
  dashboard: {},
  // analytic: [],
  country: [],
  post: [],
  user: [],
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case ActionType.CHART_ANALYTIC:
      return {
        ...state,
        user: action.payload.user,
        post: action.payload.post,
      };
    case ActionType.COUNTRY_USER_ANALYTIC:
      
      return {
        ...state,
        country: action.payload,
      };
    // case ActionType.USER_CHART_ANALYTIC:
    //   return {
    //     ...state,
    //     user: action.payload.user,
    //     post: action.payload.post,
    //   };
    default:
      return state;
  }
};
