import { combineReducers } from "redux";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { userReducer } from "./user/reducer";
import adminReducer from "./admin/reducer";
import postReducer from "./post/reducer";
import settingReducer from "./setting/setting.reducer";
import { locationReducer } from "./location/reducer";
import { loaderReducer } from "./Lodaer/loader.reducer";

import { userProfileReducer } from "./userProfile/reducer";
import { notificationReducer } from "./notification/reducer";
import { analitycReducer } from "./charts/reducer";
import { categoryListReducer } from "./categoryList/reducer";

export default combineReducers({
  dashboard: dashboardReducer,
  admin: adminReducer,
  user: userReducer,
  post: postReducer,
  setting: settingReducer,
  isLoading: loaderReducer,
  userProfile: userProfileReducer,
  location: locationReducer,
  notification:notificationReducer,
  charts:analitycReducer,
  category:categoryListReducer

});
